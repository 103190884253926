<template>
  <div class="Volley1">
    <div class="banner">
      <div class="title">Volley 1</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/Volley_1配图/PC首页OS.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/Volley_1配图/PC首页cpu.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <ImgVideo :video="video"></ImgVideo>
    <div class="Display">
      <div class="w">
        <div class="title">60° Wide Rotation Angle</div>
        <div class="img">
          <img src="../../../assets/Volley_1配图/PC第三页.png" alt="" />
        </div>
        <div class="footer">
          <div>
            <div class="small-title">Main Display</div>
            <div class="text">15.6” 1920 x 1080</div>
            <div class="text">Bezel-less Display</div>
          </div>
          <div>
            <div class="small-title">Secondary Display</div>
            <div class="text">Optional : 10.1” 1280 x 800</div>
          </div>
        </div>
      </div>
    </div>
    <div class="Processor">
      <div class="w">
        <div class="title">Processor</div>
        <div class="img">
          <img src="../../../assets/Volley_1配图/PC第四页.png" alt="" />
        </div>
        <div class="footer">
          <div>
            <div class="small-title">Intel® Processor N97</div>
            <div class="text">CPU (Optional: Intel® Core™ i3-N300)</div>
          </div>
          <div>
            <div class="small-title">3.6 GHz Max</div>
            <div class="text">CPU Clock Speed</div>
          </div>
          <div>
            <div class="small-title">8G DDR4+256G SSD</div>
            <div class="text">Memory</div>
          </div>
        </div>
      </div>
    </div>
    <div class="interfaces">
      <div class="w">
        <div class="title">Abundant expansion interfaces</div>
        <div class="img">
          <img src="../../../assets/Volley_1配图/PC第五页.png" alt="" />
        </div>
      </div>
    </div>
    <div class="Volley1con">
      <div class="w">
        <div class="titleBox">
          <div class="title">Volley 1</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">Processor</div>
              <div class="text">
                Intel® Processor N97 (Alder Lake), 6M Cache, Max. 3.6 GHz
              </div>
              <div class="text">
                Optional: Intel® Core™ i3-N300, 6M Cache, Max. 3.80 GHz
              </div>
            </div>
            <div class="itemB">
              <div class="title">System Memory</div>
              <div class="text">DDR4 SO-DIMM 8GB</div>
              <div class="text">(3200MTs, up to 16GB)</div>
            </div>
            <div class="itemB">
              <div class="title">Storage</div>
              <div class="text">Windows 10/11 and Linux</div>
            </div>
            <div class="itemB">
              <div class="title">OS</div>
              <div class="text">Windows 10/11 and Linux</div>
            </div>
            <div class="itemB">
              <div class="title">LCD Panel</div>
              <div class="text">15.6-inch Full HD LCD</div>
              <div class="text">1920 x 1080</div>
            </div>
            <div class="itemA">
              <div class="title">Touch Panel</div>
              <div class="text">True-Flat Projected Capacitive Multi Touch</div>
            </div>
            <div class="itemB">
              <div class="title">Speaker</div>
              <div class="text">1 x 2W High Quality Speaker</div>
            </div>
            <div class="itemB">
              <div class="title">Power Adapter</div>
              <div class="text">12V/5A 60W Adapter (System)</div>
            </div>
            <div class="itemB">
              <div class="title">Expansion Slot</div>
              <div class="text">M.2 M-Key 2230</div>
              <div class="text">PCIe x1 + USB2.0</div>
              <div class="text">MINI-PCIE</div>
              <div class="text">PCIEPCIe x 1 + USB2.0 + SIM</div>
            </div>
            <div class="itemA">
              <div class="title">Optional</div>
              <div class="text">
                2nd Display: 10.1" (1280x800) / 15.6" (1920x1080), LCD Display
                Optional PCAP Touchscreen
              </div>
              <div class="text">Wi-Fi: Wi-Fi IEEE 802.11 a/b/g/n/ac</div>
            </div>
            <div class="itemA">
              <div class="title">Peripheral Ports</div>
              <div class="text">Operation Condition:</div>
              <div class="text">
                0°C to +40°C / 20 to 80% without condensation
              </div>
              <div class="text">Storage Condition:</div>
              <div class="text">
                -20°C to +55°C / 20 to 85% without condensation
              </div>
            </div>
            <div class="itemC">
              <div class="title">Interface</div>
              <div class="text">2 x USB 3.2 Type A</div>
              <div class="text">
                1 x USB 3.2 Type C (DP ALT, DC OUT(5V@3A, 12V@3A))
              </div>
              <div class="text">8 x USB 2.0 Type A</div>
              <div class="text">1 x RJ45 10/100/1000Mbps Giga Ethernet</div>
              <div class="text">2 x COM (RJ45), 5V/12V by BIOS</div>
              <div class="text">1 x COM (RS232), RI / 5V/ 12V by jumper</div>
              <div class="text">1 x HDMI Port</div>
              <div class="text">1 x Line-out / MIC-in</div>
              <div class="text">
                1 x RJ11 port supports 2 cash drawer, 12V/24V by jumper
              </div>
              <div class="text">1 x DC Jack 2.5mm</div>
            </div>
            <div class="itemD">
              <div class="title">Mechanical</div>
              <div class="text">Dust and Water: IP65 on Front Panel</div>
              <div class="text">
                Dimension: 365 (W) x 182 (D) x 338 (H) (mm)
              </div>
              <div class="text">Weight: 4.0 kg</div>
              <div class="text">Certification: CE/FCC/LVD</div>
              <div class="text">TPM: FTPM</div>
            </div>
            <div class="itemE">
              <img src="../../../assets/Volley_1配图/pc参数配图.png" alt="" />
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">Volley 1</div>
              <div class="text">PDF(842KB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Volley_1.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <!-- <div class="itemB">
              <div class="title">D1 User Manual</div>
              <div class="text">PDF(2.26MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/D1_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import video from '../../../assets/Volley_1配图/Compax_Volley_1_Video.mp4'
import ImgVideo from '@/components/imgVideo/index'
export default {
  components: {
    ImgVideo
  },
  data() {
    return {
      video
    }
  }
}
</script>

<style lang="less" scoped>
.Volley1 {
  background: rgba(0, 0, 0, 1);
  .banner {
    padding: 53px;
    height: 1000px;
    background: url('../../../assets/Volley_1配图/PC首页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 86.88px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 60px;
      font-weight: 700;
    }
    .osDMS {
      margin-top: 28px;
      padding: 0px 228px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 363.12px;
        height: 73.4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .Display {
    background: rgba(0, 0, 0, 1);
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 115px 0px 90px 0px;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .footer {
      margin-top: 60px;
      display: flex;
      justify-content: space-around;
      .small-title {
        font-size: 36px;
        color: #005cff;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .text {
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
      }
    }
  }
  .Processor {
    background: rgba(0, 0, 0, 1);
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 115px 0px 90px 0px;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .footer {
      margin-top: 60px;
      display: flex;
      justify-content: space-around;
      .small-title {
        font-size: 36px;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .text {
        font-size: 20px;
        line-height: 25px;
        color: #005cff;
      }
    }
  }
  .interfaces {
    background: rgba(0, 0, 0, 1);
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 115px 0px 90px 0px;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .Volley1con {
    margin-top: 180px;
    background: rgba(0, 0, 0, 1);
    padding: 0px 11px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 95px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      display: flex;
      justify-content: space-between;
      margin-top: 21px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 140px;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 219px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 461px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 345px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemE {
        background-color: #005cff7f;
        margin-top: 23px;
        width: 338px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 312px;
          height: 158px;
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
